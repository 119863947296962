import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

const Image = styled(Plaatjie)`
  object-fit: cover;

  & img {
    object-position: top;
  }

  @media (min-width: 992px) {
    height: 100%;
    width: 359px;
  }

  @media (max-width: 991px) {
    height: 257px;
    width: 259px;
  }
`

const Content = styled(ParseContent)`
  & h2,
  & p {
    color: ${({ theme }) => theme.color.light};
  }

  & h2 {
    font-size: ${({ theme }) => theme.font.size.ultra};

    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.mega};
    }
  }

  & p:last-child {
    margin-top: 4rem;

    @media (max-width: 991px) {
      margin-top: 2rem;
    }
  }
`

const StyledCta = styled.section`
  background-color: ${({ theme }) => theme.color.greyBg};
`

interface CtaProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Cta
}

const Cta: React.FC<CtaProps> = ({ fields }) => (
  <StyledCta className="py-lg-5">
    <div className="container py-5">
      <div className="row py-lg-5">
        <div className="col-5 col-sm-6 d-flex justify-content-end pe-md-5">
          <Image image={fields.image} className="mr-lg-4" alt="VT Tuinen" />
        </div>
        <div className="col-7 col-sm-6 d-flex align-items-center">
          <Content content={fields.description} />
        </div>
      </div>
    </div>
  </StyledCta>
)

export default Cta
